.auth-container {
  background-repeat: no-repeat;
  background-size: cover; 
  display: flex;
  align-items: full; 
  justify-content: full; 
}

.image-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: flex-end;
}

.text-on-image {
  position: absolute;
  top: 20%; 
  text-align: center;
}

.text-on-image p {
  color: #00adef;
  font-size: 45px;
  font-weight: 450;
  font-family: 'openSans-Regular', Roboto, sans-serif;
}

.auth-render-section {
  background: #fff;
  width: 450px;
  border-radius: 0px;
  padding: 30px 40px;
  border: 2px solid #fff;
  box-shadow: none;
  max-height: 100vh; 
  overflow-y: auto; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
}

.auth-render-section img {
  display: block;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: start;
  justify-content: start; 
}

.language-select {
  position: absolute;
  top: 20px;
  right: 20px;
}