.top-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background: #fdfdfd;
  box-shadow: 0 2px 3px #7b7373;
  display: flex;
  align-items: center;
  z-index: 1000;
  padding: 0 30px;
  padding-left: 20px;
  justify-content: space-between;
}

.render-section {
  margin-top: 56px;
  display: flex;
  height: calc(100vh - 56px);
  overflow: auto;
  background-color: #fff;
}
 .left-section {
   /* width: 200px;  */
  background: #044c74;
  overflow-y: scroll;   
     overflow-x: hidden;  
     margin-top:'500px'
}
.right-section {
  /* width: 100%;
  padding: 20px 20px; */
  /* background-color: #fff; */
  /* padding-left: 100px; */
   /* overflow-x: scroll;  */
   /* overflow-y: scroll;   */
   position: relative; 
}

.current-day-time-text {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #636799;
  border-radius: 4px;
  padding: 7px 15px;
  text-align: center;
  color: #fff;
}

.top-banner-right {
  display: flex;
  align-items: center;
}

.top-banner-right img {
  cursor: pointer;
}

.menu-item-parent {
  font-size: 14px;
  text-align: center;
  padding: 10px 14px;
  border-bottom: 1px solid #5c5a5a;
  cursor: pointer;
  display: flex;
  color: #fff !important;
  position: relative;
  /* transition: 0.8s; */
}

.parent-menu-name {
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: block;
  color: #fff !important;
  position: relative;
}

.icon-style {
  font-size: 24px !important;
  font-weight: 300;
  color: #fff !important;
}

.arrow-icon-style {
  font-size: 19px;
  position: relative;
  right: 10px;
  top: 12px;
  bottom: 0;
  color: #fff !important;
}

.sub-menu-options {
  color: #fff;
  text-align: center;
}

.sub-menu-options .menu-name {
  padding: 10px 10px;
  cursor: pointer;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #5c5a5a;
  background: #044c74;
  text-align: left;
  padding-left: 35px;
}

.sub-menu-options .menu-name:hover {
  background-color: #ee5b66;
}
/* .active {
  background-color: #ee5b66 !important;
}  */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .left-section {
    /* background-color: rgba(0, 0, 0, 0); */
    /* -webkit-background-clip: text; */
    /* uncomment following line to see the trick in actions */
    /* -webkit-text-fill-color: transparent;
     transition: background-color .8s; */
     overflow-x: hidden;  
  }
  .left-section:hover {
    /* background-color: rgba(0, 0, 0, 0.18); */
  }
  .left-section::-webkit-scrollbar {
    width: 15px;
    height: 12px;
    background-color:#044366;
  }
  .left-section::-webkit-scrollbar-track {
    display: none;
  }
  .left-section::-webkit-scrollbar-thumb {
    border: 4px solid #044366;
    background: rgb(183, 183, 183);
    border-radius: 12px 12px 12px 12px;
    margin: 0px auto;
  }
}

.icon-style {
  font-size: 20px;
  margin-right: 10px;
}

/* .dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #044c74;
  border-radius: 0;
  border-bottom: 1px solid rgb(92, 90, 90);
  border: none;
  overflow: hidden;
  left: 150px
}
.dropdown:hover  {
  display: block;
  background-color: #ee5b66;
} */

.sub-menu-options .dropdown:hover .dropdown-content {
  display: block;
}

.animated:active {
  /* min-height: fit-content; */
  transition: cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
/* .animated:active {
  transform: translateY(3px);
} */

.ant-menu-submenu-arrow {
  color: #fff !important;
}

.ant-menu-sub.ant-menu-inline {
  background: #044366;
}

.ant-menu-item a {
  color: #fff !important;
  padding-left: 15px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  padding-left: 13px !important;
  color: #fff !important;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item {
  display: inline;
}

.ant-menu-submenu-popup {
  top: 0px !important;
  right: 5% !important;
  left: auto !important;
  width: 400px;
}

.ant-menu-item a:hover {
  color: #fff !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
  background-color: #ee5b66;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ee5b66 !important;
}
.ant-menu-title-content-selected {
  background-color: #ee5b66;
}

.ant-menu-vertical.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]) {
  background-color: #044c74;
  color: #fff;
}


.ant-menu-item:active, .ant-menu-submenu-title:active{
  background-color: #044c74;
  color: #fff;
}

.ant-menu-submenu-popup {
  left: 203px !important;
  width: 256px !important;
  top: 65px !important;
}

.sub {
  padding-left: 30px !important;
}

.sub .ant-menu-submenu-arrow {
  rotate: 270deg;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}

.ant-menu-vertical .ant-menu-item:active,
.ant-menu-vertical-left .ant-menu-item:active,
.ant-menu-vertical-right .ant-menu-item:active,
.ant-menu-inline .ant-menu-item:active {
  background-color: #ee5b66;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item {
  display: block !important;
}

.ant-menu-item:hover{
  background-color: #ee5b66;
}

.selected{
  background-color: #ee5b66 !important;
}


@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;

  }
  .mdmenu{
    display: contents;
  }
}



/* .mdmenu{
  background-color:'black';
  

} */

.menu-name1 {
  display: block;
  text-overflow: ellipsis;
  max-height: 3em;
  
  
}


 /*.sidebar{
  overflow-x: hidden;
  overflow-y: scroll;
  background: #044c74;
  color: #fff;
  height: calc(100vh - 56px);


  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #53708d;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aeb4ba; 
  
}

}  */

.sidebar {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 56px);
  background: #044c74;



}

.sidebar-button {
  background: #044c74;
  border: none;
  width: 240px;
  /* transition: width 0.3s ease; */
  
}

.sidebar-button.closed {
  width: 50px;
}

.sidebar-content {
  width: 205px;
  background: #044c74;
}

.menu-item-parent {
  border-bottom: 1px solid #5c5a5a;
}




@media (max-width: 768px) {
  .auth-section {
    width: 100%;
    padding: 20px;
    /* Adjust padding for smaller screens */
    margin: 0 auto;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    /* Add some border-radius for a smoother look */
  }
}

@media (max-width: 480px) {
  .auth-section {
    width: 100%;
    padding-right: 70px;
    margin: 0 auto;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
}

.submit-sections {
  background-color: white;
  margin-top: 5%;
  margin-left: 29%;
  margin-right: 15%;
  margin-bottom: 5%;
  align-items: center;
  /* height: 10px; */

}

.login-form-button {
  border-radius: 20px;
  padding: '10px 20px';
  font-size: '16px';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

/* Media query for screens up to 768px */
@media (max-width: 768px) {
  .login-form-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Media query for screens up to 480px */
@media (max-width: 480px) {
  .login-form-button {
    padding: 9px;
    font-size: 12px;
    display: block;
    /* Change display to block for smaller screens */
    margin-top: 0px;
    /* Add some margin between buttons */
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Increase the height by setting a negative value */
  background-color: rgba(0, 80, 113, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  padding: 50px;
  text-align: center;
}

/* Media query for screens up to 768px */
@media (max-width: 768px) {
  .overlay {
    display: none;
    /* Hide the overlay on mobile devices */
  }
}

/* Media query for screens up to 480px */
@media (max-width: 480px) {
  .overlay {

    display: none;

  }
}


