.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background: none;
}

.roles-table tbody,
.roles-table td,
.roles-table th,
.roles-table thead,
.roles-table tr {
  text-align: center;
}

.primary-title {
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #1c1c1c;
  margin: 0px;
  border-bottom: 1px solid #cccccc;
}

.primary-title1 {
  font-weight: 600;
  font-size: 27px;
  line-height: 29px;
  color: #1c1c1c;
  /* margin: 0px;
  border-bottom: 1px solid #cccccc; */
  text-align: center;
}

.primary {
  font-size: 29px;
  font-weight: 600;
  color: #1c1c1c;

}

.secondary-title {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #1c1c1c;
  margin: 10px 0px;
  border-bottom: 1px solid #cccccc;
}

.row-search-style {
  margin-bottom: 15px;
  margin-top: 20px;
}

.clear-btn-space {
  margin-right: 20px;
}

.create-btn-style {
  margin-right: 10px;
}

.auth-status-style {
  width: 150px !important;
}

.caption {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;
}

.operation-link-style {
  margin: 0 5px;
  cursor: pointer;
}

.ant-table-thead>tr>th,
.ant-table tfoot>tr>th {
  padding: 6px 16px !important;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #737ae6 !important;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.035em;

  /* background: #044c74 !important; */
  /* color: #fff !important; */
  /* border-right: 1px solid #044c74 !important; */
}

.ant-table-tbody>tr>td,
.ant-table tfoot>tr>td {
  padding: 6px 16px !important;
  /* font-family: Araboto_Light_400; */
}

.pagination-section {
  margin-top: 20px;
}

.ant-form-item-label>label {
  color: #1c1c1c;
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 500;
  /* font-family: Araboto_Medium_400; */
}

.label-header-style {
  padding-bottom: 5px;
  font-weight: 300;
  font-size: 14px;
}

.label-data-style {
  margin-left: 15px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 15px;
}

.data-style {
  margin-left: 15px;
}

.lbl-custom-style label {
  display: block;
  padding-bottom: 5px;
  /* font-weight: 300; */
  font-size: 14px;
}

.lbl-custom-style span {
  display: block;
  padding-bottom: 10px;
  /* font-weight: 600; */
  font-size: 15px;
}

.custom-tbl-style th {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #ffffff;
  /* color: #fff; */
  text-align: center !important;
  padding: 10px 8px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  vertical-align: middle;
}

.customer-details h5 {
  font-size: 1.2rem !important;
  text-transform: uppercase;
}

.custom-tbl-style {
  border-collapse: collapse !important;
  width: 100%;
}

.custom-tbl-style .ant-input,
.custom-tbl-style .ant-input-password,
.custom-tbl-style .ant-picker {
  background: none;
  height: 30px;
  /* border: none; */
}

.custom-tbl-style .ant-select-selector {
  background: none;
  height: 30px !important;
  /* border: none; */
}

.custom-tbl-style .ant-select-selection-item {
  line-height: 27px !important;
}

.search-section {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 10px 15px 0;
}

.search-section .ant-form-item {
  margin-bottom: 15px;
}

.btn-group {
  position: absolute;
  top: 12px;
  right: 0;
  background: #fff;
  padding-left: 20px;
}

.ant-input,
.ant-input-password,
.ant-picker {
  border-radius: 5px;
  font-weight: 400;
  height: 36px;
  outline: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.ant-input-password .ant-input {
  background-color: initial;
  height: auto;
}

.animate-charcter {
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-animation: textclip 2s linear infinite;
  animation: textclip 2s linear infinite;
  background-clip: border-box;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(-225deg,
      #231557,
      #44107a 29%,
      #ff1361 67%,
      #fff800);
  background-size: auto auto;
  background-size: 200% auto;
  color: #fff;
  display: inline-block;
  font-size: 45px;
  text-transform: uppercase;
}

.forgot {
  text-align: right !important;
}

.gradient {
  width: 100px;
  height: 100px;
  /* background: linear-gradient(to right, #5e2563, #6094ea ); */
  /* background: linear-gradient(
    to bottom,
    #FF57B9 0%, Color at the top
  
    #A704FD 100% Color at the bottom
    #ce9ffc 0%,
    #7367F0 100%
   
  ); */
  background: linear-gradient(to right, #05711e, #0e6ca7);
}

.gradient-box {
  width: 100px;
  height: 100px;

  background: linear-gradient(to bottom,
      /* #7117ea 0%, Color at the top */
      /* #00ff00 30%, Color in the center */
      /* #ea6060 100% Color at the bottom */
      #a3e8df 0%,
      /* Color at the top */
      /* #00ff00 30%,  /* Color in the center */
      #f8ea8c 100%
      /* Color at the bottom */

    );

}

/* Styles for medium-sized screens (e.g., tablets) */
@media only screen and (max-width: 768px) {
  .gradient-box {
    /* Adjust styles for medium-sized screens */
  }
}

/* Styles for small screens (e.g., mobile devices) */
@media only screen and (max-width: 480px) {
  .gradient-box {
    /* Adjust styles for small screens */
  }
}

.button1 {
  border-radius: 2px;
}

.button2 {
  border-radius: 4px;
}

.button3 {
  border-radius: 8px;
}

.button4 {
  border-radius: 12px;
}

.button5 {
  border-radius: 50%;
}

.baground {
  background-color: rgb(243, 247, 248);
  color: black;
  /* margin-left: 5%; */

}

.baground1 {
  background-color: rgb(198, 221, 226);

}

.padding {
  margin-top: 20px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

}

.scrollable-container {
  overflow-y: scroll;
  /* Show vertical scrollbar */
  /* You can add more styles like height, width, etc. */
}

.content1 {
  display: 'block';
  width: '100 %';
  overflow-y: 'auto';
  -webkit-overflow-scrolling: 'touch';
  -ms-overflow-style: '-ms - autohiding - scrollbar'
}