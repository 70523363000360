.lightTheme {
  --login_bg: #e5e5e5;
  --primary_1: #fff;
  --primary_2: #f15d2a;
  --secondary_1: #f15d2a;
  --secondary_2: #de9700;
  --primary_title: #343c6a;
  --secondary_title: #1c1c1c;
  --border_color: #fff;
  --layout_color: #1c1c1c;
  --nav_color: #2d60ff;
  --nav_color_primary: #505887;
  --box_shadow: none;
  --portfolio-card-assest: #71c1b4;
  --portfolio-card-liability: #fbb12b;
  --portfolio-card-assest-border: #71c1b4;
  --portfolio-card-liability-border: #fbb12b;
}
