body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e5e5e5;
  color: #1c1c1c !important;
}

#root,
.app-container,
.auth-container {
  height: inherit;
  overflow: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #044c74;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0px auto;
  top: 40%;
}

.ant-modal-body {
  padding: 5px 24px !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.download-file {
  text-decoration: underline;
  color: #1890ff;
}

.password-criteria {
  margin-left: 50px;
  border-radius: 20px;
  padding: 20px;
  background-color: #E7EFF5;
}

.selected-menu {
  color: #2d2d2d !important;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13px;
  padding: 0 10px;
  height: 10px;
  align-items: center;
}

.right-aligned-amount {
  text-align: right;
}

.margin-top {
  margin-top: 10px;
}

.margin-top1 {
  margin-top: 30px;
}

.button-style {
  border-color: #1890ff;
  color: #1890ff;
}

/* .ant-select.ant-select-in-form-item{
  width: 100% !important;
} */