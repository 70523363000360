@font-face {
  font-family: Araboto_Bold_400;
  src: url("./fonts/Araboto_Bold_400.ttf") format("trueType");
}
@font-face {
  font-family: Araboto_Normal_400;
  src: url("./fonts/Araboto_Normal_400.ttf") format("trueType");
}
@font-face {
  font-family: Araboto_Light_400;
  src: url("./fonts/Araboto_Light_400.ttf") format("trueType");
}
@font-face {
  font-family: Araboto_Medium_400;
  src: url("./fonts/Araboto_Medium_400.ttf") format("trueType");
}

@font-face {
  font-family: Araboto_Thin_400;
  src: url("./fonts/Araboto_Thin_400.ttf") format("trueType");
}

@font-face {
  font-family: Araboto_Black_400;
  src: url("./fonts/Araboto_Black_400.ttf") format("trueType");
}
