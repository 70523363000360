@media (max-width: 899px) { 
    .authWrapper-container {
      width: 100%;
    }
    .auth-header-wrapper {
      justify-content: center;
    }
  
    .auth-banners {
      display: none;
    }
    /* .left-section {
      display: none;
    } */
    .right-section {
      width: 100%;
      padding: 15px;
      overflow-y: auto;
    }
    .dashboard-accounts-wrapper {
      overflow: hidden;
      overflow-x: auto;
    }
  
    .dashboard-accounts-body,
    .table-body {
      width: 950px;
    }
  
    .dashboard-btn-group {
      text-align: right;
      margin-top: 20px;
    }
    .top-banner {
      padding: 0 20px !important;
    }
    .bank-logo {
      height: 45px;
    }
    .chart-filters .ant-btn {
      margin-bottom: 20px !important;
    }
  
    .dashboard-line-chart {
      margin-bottom: 20px;
    }
  
    .acc-details-header-btn-group {
      margin-top: 20px;
    }
  
    .payee-search {
      margin-bottom: 20px;
    }
  
    .payee-render-section {
      padding: 15px;
    }
  
    .deposit-render-section {
      padding: 15px;
    }
  
  
    
    .scheduled-trns-btn{
      text-align: right;
      margin-bottom: 10px;
    }
  
    .txt-area-style{
      width: 320px;
    }
  
    .dashboard-btn-group .ant-btn{
      margin-bottom: 5px;
    }
  
    .contact-tel-style{
      display: block;
      margin-bottom: 10px;
    }
  
    
    .paragraph {
      width: 100%;
    }
  
    .acc-shortname{
      width: 80%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: sub;
    }
  
    .payee-details-btn-group .ant-btn{
      margin-bottom: 15px;
    }
  
    .payee-details-btn-group{
      text-align: center;
      display: block !important;
    }
    .payee-transfer-btn-group .ant-btn{
      margin-right: 10px;
    }
  } 
  
  
  
  @media only screen and (min-width: 900px) and (max-width: 1099px) {
    .authWrapper-container {
      width: 39.3%;
    }
    .auth-banners {
      width: 60.7%;
    }
    #authWrapper {
      margin: 0 30px;
    }
    .auth-banners {
      display: block;
    }
  
    .left-section {
      display: none;
    }
  
    .right-section {
      width: 100%;
      padding: 15px;
      overflow-y: auto;
    }
    .dashboard-accounts-wrapper {
      overflow: hidden;
      overflow-x: auto;
    }
  
    .dashboard-accounts-body,
    .table-body {
      width: 950px;
    }
    .dashboard-btn-group {
      text-align: right;
      margin-top: 20px;
      display: flex;
    }
    .top-banner {
      padding: 0 20px !important;
    }
    .bank-logo {
      height: 50px;
    }
  
    .chart-filters .ant-btn {
      margin-bottom: 20px !important;
    }
  
    .acc-details-header-btn-group {
      display: flex;
    }
  
    .payee-search {
      margin-bottom: 20px;
    }
    .payee-render-section {
      padding: 20px;
    }
  
    .deposit-render-section {
      padding: 20px;
    }
  
    .otp-wrapper {
      width: 400px;
    }
  
    .scheduled-trns-btn{
      text-align: right;
      margin-bottom: 10px;
    }
  
    .txt-area-style{
      width: 450px;
    }
    
    .duration-fields{
      display: flex;
    }
  
    .location-search-txt{
      width: 350px;
    }
  
    .paragraph {
      width: 80%;
    }
  
    .payee-transfer-btn-group .ant-btn{
      margin-right: 30px;
    }
  
    
  } 
  
  @media (min-width: 1100px) {
    /* .authWrapper-container {
      width: 36.3%;
    }
    .auth-banners {
      width: 63.7%;
    }
  
    #authWrapper {
      width: 1100px;
    } */
  
    .left-section {
      width: 270px;
      height: calc(100vh - 77px);
      /* padding: 10px; */
      background: #fff;
      z-index: 110;
      /* padding-top: 15px; */
      position: fixed;
      overflow: auto;
      scroll-behavior: smooth;
      /* border-right: 1px solid #dbdada; */
    }
    .right-section {
      width: 100%;
      padding: 25px 25px;
      padding-left: 290px;
      overflow-y: auto;
    }
    .dashboard-profile-details {
      display: flex;
    }
  
    .bank-logo {
      height: 66px;
    }
    .acc-details-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .acc-details-header-btn-group {
      display: flex;
    }
  
    .payee-search {
      width: 300px;
    }
  
    .payee-render-section {
      padding: 10px 50px;
    }
  
    .deposit-render-section {
      padding: 10px 40px;
    }
  
    .otp-wrapper {
      width: 400px;
    }
  
    .scheduled-trns-btn {
      position: "absolute";
      top: "9px";
      right: "13px";
    }
  
    .cheque-search {
      position: absolute;
      top: 8px;
      right: 0;
    }
    .txt-area-style{
      width: 450px;
    }
  
    .payee-details{
      display: flex;
      justify-content: space-between;
    }
  
    .duration-fields{
      display: flex;
    }
    .location-search-txt{
      width: 350px;
    }
  
    .paragraph {
      width: 80%;
    }
  
    .payee-transfer-btn-group .ant-btn{
      margin-right: 30px;
    }
  } 
  